const PATH_NAMES = {
  ROOT: '/',
  PROFILE: 'profile',
  PLANS: '/plans',
  CREATE_PLANS: '/plans/create',
  //TODO replace with sandbox
  PLANS_ID: '/plans/:id',
  PLAN_DASHBOARD: 'plans/:id/dashboard',
  PLAN_DASHBOARD_BASE: 'plans/dashboard',
  CALENDAR_ROLLOVER: 'rollover',
  ACTIVITIES: 'activities',
  UNDER_CONSTRUCTION: 'GeneralConstruction',
  CREATE_DISCOUNTS: 'creatediscount',
  DISCOUNT_DETAILS: 'discounts',
  NOTFOUND: '*',
  GUIDELINE_LOG: 'guideline-log',

  //pcrs
  PCRS: '/pcrs',
  PCR_SANDBOX: 'pcrs/:id',
  PCR_FRONTLINE_DETAILS: 'pcrs/:id/frontline',
  PCR_FRONTLINE_FOB_ADJUSTMENT: 'pcrs/:id/fobadjustment',
  PCR_DISCOUNT_DETAILS: 'pcrs/:id/discounts',
  PCR_CREATE_DISCOUNT: 'pcrs/:id/discounts/create',
  PCR_MASS_COPY_HOME: 'pcrs/:id/masscopy/',
  PCR_MASS_COPY: 'pcrs/:id/masscopy/:massCopyType',
  PCR_DISCOUNT_CALENDAR: 'pcrs/:id/calendar',
  PCR_INFORMATION: 'pcrs/:id/information',
  PCR_GUIDELINES_SANDBOX: 'pcrs/:id/guideline-log',
  IMPORT_WIZARD: '/import',
  NATIONAL_FOB_DELETE: '/fobdelete',

  //plans
  // PLANS_SANDBOX: 'plans/:id',
  // this is duplicated
  PLAN_FRONTLINE_DETAILS: 'plans/:id/frontline',
  PLAN_FRONTLINE_FOB_ADJUSTMENT: 'plans/:id/fobadjustment',
  PLAN_DISCOUNT_DETAILS: 'plans/:id/discounts',
  PLAN_CREATE_DISCOUNT: 'plans/:id/discounts/create',
  PLAN_MASS_COPY: 'plans/:id/masscopy/:massCopyType',
  PLAN_DISCOUNT_CALENDAR: 'plans/:id/calendar',
  PLAN_INFORMATION: 'plans/:id/information',
  PLAN_GUIDELINES_SANDBOX: 'plans/:id/guideline-log',
  NEW_BRAND_PACKAGE: '/newbrandpackage',
  PLAN_CALENDAR_ROLLOVER: '/plans/:id/calendar-rollover',

  // NewProductSystem NPS
  NPS_HOME: 'nps',
  NPS_LEGAL_REVIEW: 'nps/legal-review',
  NPS_RM_REVIEW: 'nps/rm-review',
  NPS_FINAL_REVIEW: 'nps/final-review',
  NPS_COMPLETED_REQUESTS: 'nps/completed'
};

export default PATH_NAMES;
